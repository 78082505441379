<template>
  <page-template :is-multi-user="$getIsMultiUser()" @logout="logout">
    <v-layout align-center class="overflow-hidden" fill-height justify-center>
      <v-card class="login-card pa-10" :class="{ 'is-mobile': isMobile }" @mouseleave.native="animated = false" @mouseover.native="animated = true">
        <div class="body-card-mobile">
          <logo-login :animated="animated" />
          <v-spacer />
          <h3 class="login-title text-center">{{ title() }}</h3>

          <div v-if="twoStepsVerification">
            <two-steps-verification
              v-if="emailReceived"
              :dontVerifyDeviceAgain.sync="dontVerifyDeviceAgain"
              :loading="loading"
              :pinError="pinError"
              :showReSendButton="showReSendButton"
              :timer="timer"
              :username="email"
              @email-not-received="emailReceived = false"
              @go-back=";(emailReceived = true), (twoStepsVerification = false)"
              @remove-error="pinError = ''"
              @resend-code="resendCode"
              @send-code="login(false, $event)"
            />
            <email-not-received
              v-else
              :confirmationToken.sync="confirmationToken"
              :hiddenPhone="hiddenPhone"
              @email-received=";(emailReceived = true), (pinError = '')"
              @go-back=";(emailReceived = true), (twoStepsVerification = false)"
              @remove-error="pinError = ''"
              @send-code="login(false, $event)"
            />
          </div>
          <password-recover v-else-if="recoverPassword" :default-email="email" @go-back="recoverPassword = false" />
          <mf-loading v-else-if="!hasLoginForm" :boolean-loader="loadingClients" :dynamic-amount="4" :dynamic-width="225" loader-type="dynamic">
            <clients-list
              v-if="$getIsMultiUser() && source === 'ADMIN'"
              :clients="clients"
              :loading-accounts="loadingAccounts"
              :search.sync="search"
              @load-more="waitAndGetData"
            />
            <v-form v-else class="pa-3" @submit.prevent="login(true)">
              <v-layout>
                <v-text-field id="user" v-model="email" :error-messages="emailError" label="E-mail" outlined required @keydown.enter="login(true)" />
              </v-layout>
              <v-text-field
                id="password"
                v-model="password"
                :append-icon="visibility ? 'visibility' : 'visibility_off'"
                counter
                label="Senha"
                outlined
                required
                :type="visibility ? 'password' : 'text'"
                @click:append="() => (visibility = !visibility)"
                @keydown.enter="login(true)"
              />
              <v-layout justify-center>
                <v-card-actions
                  class="d-block filled pa-0 no-text-select"
                  @mousedown="startAdminPress"
                  @mouseleave="clearAdminPress"
                  @mouseup="clearAdminPress"
                  @touchend="clearAdminPress"
                  @touchstart="startAdminPress"
                >
                  <v-btn id="login-button" class="btn-submit-form" color="primary" :disabled="!isFullLoginData || loading" :loading="loading" type="submit">
                    Acessar
                  </v-btn>
                  <p class="text-center mt-8 link" @click="forgotPassword">Esqueci minha senha</p>
                </v-card-actions>
              </v-layout>
            </v-form>
          </mf-loading>
          <google-signin-button v-else class="d-flex justify-center mt-5" :source="source" @adminLogin="adminLogin" />
          <mf-loading-dialog :loading="loadingRedirect" />
        </div>
      </v-card>
      <request-email-confirmation v-model="triggerEmailConfirmation" :current-email="currentLoggedEmail" :password="password" />
      <internal-login v-model="showInternalLogin" :admin-login="adminLogin" :source="source" />
    </v-layout>
  </page-template>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { mapState, mapActions } from 'pinia'
import { enums } from '@mf-node/mf-vue-components'
import { required, email } from '@vuelidate/validators'

import { useStore as mainUseStore } from '@/store'
import PageTemplate from '../components/PageTemplate.vue'
import { getFingerprint } from '@/services/fingerprint.js'
import { getAncestorOrigin } from '@/helpers/ancestorOrigins'
import { useStore as loginUseStore } from '@/modules/login/store'
import GoogleSigninButton from '../components/GoogleSigninButton.vue'
import { getIndustryAccountIdBySource } from '@/helpers/industry'
import { QUERY_USER_ACCOUNTS, MUTATION_CONFIRM_DEVICE_CODE, MUTATION_RESEND_CONFIRM_CODE, MUTATION_SELECT_USER_ACCOUNT } from '@/modules/login/graphql'

export default {
  name: 'Login',
  components: {
    PageTemplate,
    GoogleSigninButton,
    ClientsList: () => import('@/components/ClientsList.vue'),
    LogoLogin: () => import('@/modules/login/views/components/LogoLogin.vue'),
    InternalLogin: () => import('@/modules/login/views/components/InternalLogin.vue'),
    EmailNotReceived: () => import('@/modules/login/views/components/EmailNotReceived.vue'),
    TwoStepsVerification: () => import('@/modules/login/views/components/TwoStepsVerification.vue'),
    RequestEmailConfirmation: () => import('@/modules/login/views/components/RequestEmailConfirmation.vue'),
    PasswordRecover: () => import('@/modules/login/views/components/PasswordRecover.vue'),
  },
  setup() {
    return { v$: useVuelidate('', '', { $scope: false }) }
  },
  data: () => ({
    adminPressTimer: null,
    showInternalLogin: false,
    email: '',
    password: '',
    animated: false,
    visibility: true,
    loading: false,
    loadingRedirect: false,
    clients: [],
    triggerEmailConfirmation: false,
    currentLoggedEmail: '',
    loadingClients: false,
    confirmationToken: '',
    twoStepsVerification: false,
    emailReceived: true,
    pinError: '',
    showReSendButton: false,
    currentFingerprintAuthToken: '',
    dontVerifyDeviceAgain: true,
    timer: 0,
    interval: null,
    recoverPassword: false,
    limit: 20,
    cursor: '',
    search: '',
    hiddenPhone: '',
    timeout: null,
    loadingAccounts: false,
  }),
  computed: {
    ...mapState(mainUseStore, {
      source: 'getCurrentSource',
      hasLoginForm: 'getHasLoginForm',
      directRediret: 'getDirectRedirect',
      redirectUrlAfterAutentication: 'getRedirectUrlAfterAutentication',
    }),

    isFullLoginData() {
      return this.email && this.password
    },

    emailError() {
      if (this.v$.email?.$error) {
        if (this.v$.email.required.$invalid) return 'Insira um e-mail.'
        if (this.v$.email.email.$invalid) return 'Insira um e-mail válido.'
      }
      return ''
    },

    isMobile() {
      return /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },

    isIndustryLogin() {
      return this.source === 'AMBEV'
    },
  },
  watch: {
    search(newVal, oldVal) {
      this.cursor = ''
      this.waitAndGetData(true)
    },
  },
  mounted() {
    if (this.$getIsMultiUser()) {
      this.adminLogin()
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    ...mapActions(loginUseStore, ['singleLogin', 'getCode', 'singleLogout']),
    startAdminPress() {
      this.adminPressTimer = setTimeout(() => {
        this.showInternalLogin = true
      }, 5000)
    },
    clearAdminPress() {
      clearTimeout(this.adminPressTimer)
    },
    title() {
      if (this.twoStepsVerification) return 'Verificação em duas etapas'
      if (this.recoverPassword) return 'Recuperação de senha'
      if (this.$getIsMultiUser() && this.source === 'ADMIN') {
        return 'Selecionar rede'
      } else {
        return 'Entrar'
      }
    },
    redirect(path) {
      this.$router.push(path).catch(() => {})
    },
    async logout() {
      this.search = ''
      this.cursor = ''
      this.loadingClients = true
      this.clients = []
      this.$setIsMultiUser(false)
      await this.singleLogout(this.$apollo)
      this.loadingClients = false
    },
    async adminLogin(isAdmin = true, token) {
      this.$setUserPermissions(['mf/admin'])
      this.$setIsAdmin(isAdmin)

      if (this.directRediret) {
        this.loadingRedirect = true
        window.top.postMessage(
          {
            type: enums.postMessageTypes.getPostMessageType('INTERNAL_USER'),
            session_id: token,
          },
          getAncestorOrigin()
        )

        const payload = {
          source: this.source,
        }
        const { authCode } = await this.getCode(this.$apollo, payload)
        window.location.href = `${this.redirectUrlAfterAutentication}?auth_code=${authCode}`
        this.loadingRedirect = false

        return
      }
      this.setMultiUserOptions(token)
      this.$setIsMultiUser(true)
    },
    async fetchSingleLoginData(isLogin, verificationCode) {
      if (isLogin && !verificationCode) {
        const fingerprint = await getFingerprint()
        this.$setFingerPrint(fingerprint)

        const response = await this.singleLogin(
          {
            login: this.email,
            password: this.password,
            source: this.source,
          },
          this.$apollo
        )

        return response
      } else {
        const {
          data: { confirmUserDevice },
        } = await this.$apollo.mutate({
          mutation: MUTATION_CONFIRM_DEVICE_CODE,
          fetchPolicy: 'no-cache',
          variables: {
            confirmationToken: this.confirmationToken,
            saveDevice: this.dontVerifyDeviceAgain,
            verificationCode,
          },
        })
        return confirmUserDevice
      }
    },
    async login(isLogin, verificationCode) {
      this.loading = true

      try {
        if (isLogin) {
          this.v$.$reset()
          this.v$.$touch()
          if (this.v$?.$error) {
            if (this.v$.email.email.$invalid) {
              this.$snackbar({ message: 'Insira um e-mail válido', snackbarColor: 'error' })
            }
            return
          }

          const {
            data: { login },
          } = await this.fetchSingleLoginData(isLogin, verificationCode)

          if (!login.emailConfirmed) {
            this.currentLoggedEmail = this.email
            this.triggerEmailConfirmation = true
            this.loading = false
            return
          }

          if (!login.deviceConfirmed) {
            this.twoStepsVerification = true
            this.loading = false
            this.hiddenPhone = login.hiddenPhone
            this.confirmationToken = login.deviceConfirmationToken
            return
          }

          if (login.multiUser) {
            this.adminLogin()
            return
          }
        } else {
          await this.fetchSingleLoginData(isLogin, verificationCode)
        }

        const payload = {
          source: this.source,
        }
        const { authCode } = await this.getCode(this.$apollo, payload)
        window.location.href = `${this.redirectUrlAfterAutentication}?auth_code=${authCode}`
      } catch (error) {
        console.log(error)
        if (error.graphQLErrors?.[0]?.message === 'User is admin') {
          this.$snackbar({ message: 'Método de login não permitido para usuários mercafacil.', snackbarColor: 'error' })
        } else if (error.graphQLErrors?.[0]?.message === 'Device confirmation code wrong') {
          this.pinError = 'Código inválido'
          this.showReSendButton = true
          this.$snackbar({ message: 'Código de verificação inválido', snackbarColor: 'error' })
        } else if (error.graphQLErrors?.[0]?.message === 'Token invalid or expired') {
          this.pinError = 'Código expirado'
          this.showReSendButton = true
          this.$snackbar({ message: 'Código de verificação já expirado e/ou já foi utilizado', snackbarColor: 'error' })
        } else {
          this.$snackbar({ message: 'E-mail e/ou senha incorretos.', snackbarColor: 'error' })
        }
      } finally {
        this.loading = false
      }
    },
    redirectToFirstPermission(permissions) {
      const firstPermission = permissions.includes('home') ? 'home' : permissions[0]

      const parent = this.$router.options.routes.find((route) => {
        if (route.meta && route.meta.pagePermission === firstPermission) return route
        else {
          if (route.children) {
            const children = route.children.find((children) => {
              if (children.meta && children.meta.pagePermission === firstPermission) return children
            })
            return children
          }
        }
      })

      if (parent.children) {
        const children = parent.children.find((children) => {
          return children.meta && children.meta.pagePermission === firstPermission
        })
        if (children.path === 'chatbot/alteracao-encarte/novo') {
          this.$router.push('/campanhas/chatbot/')
        } else {
          this.$router.push(`${parent.path}/${children.path}`)
        }
      } else {
        this.$router.push(`${parent.path}`).catch(() => {})
      }
    },
    async setMultiUserOptions(session_id) {
      this.$setIsMultiUser(true)
      this.$setSessionId(session_id)
      this.loadingClients = true
      await this.loadUserAccounts()
      this.loading = false
      this.loadingClients = false
      this.handleTimer()

      if (['STORYBOOK', 'STARLORD'].includes(this.source)) this.selectClient(this.clients[0])
      else if (this.isIndustryLogin) this.selectIndustryClient()
    },
    waitAndGetData(resetData) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadUserAccounts(resetData)
      }, 500)
    },
    async loadUserAccounts(resetData) {
      if (this.cursor == null) return
      try {
        this.loadingAccounts = true
        const { data } = await this.$apollo.query({
          query: QUERY_USER_ACCOUNTS,
          fetchPolicy: 'no-cache',
          variables: {
            cursor: this.cursor,
            search: this.search,
            limit: this.limit,
          },
        })
        this.cursor = data.userAccounts.cursor
        if (resetData) this.clients = data.userAccounts.accounts
        else this.clients = this.clients.concat(data.userAccounts.accounts)
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingAccounts = false
      }
    },
    async selectClient(client) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: MUTATION_SELECT_USER_ACCOUNT,
        fetchPolicy: 'no-cache',
        variables: {
          accountId: client.id,
        },
      })

      const payload = {
        source: this.source,
      }
      const { authCode } = await this.getCode(this.$apollo, payload)
      window.location.href = `${this.redirectUrlAfterAutentication}?auth_code=${authCode}`
    },
    async selectIndustryClient() {
      console.log('selecionado cliente da industria')
      this.loading = true
      await this.$apollo.mutate({
        mutation: MUTATION_SELECT_USER_ACCOUNT,
        fetchPolicy: 'no-cache',
        variables: {
          accountId: getIndustryAccountIdBySource(this.source),
        },
      })

      const payload = {
        source: this.source,
      }
      const { authCode } = await this.getCode(this.$apollo, payload)
      window.location.href = `${this.redirectUrlAfterAutentication}?auth_code=${authCode}`
    },
    async resendCode() {
      this.loading = true
      try {
        const {
          data: {
            resendUserDeviceConfirmation: { deviceConfirmationToken },
          },
        } = await this.$apollo.mutate({
          mutation: MUTATION_RESEND_CONFIRM_CODE,
          fetchPolicy: 'no-cache',
          variables: { deviceConfirmationToken: this.confirmationToken },
        })
        this.confirmationToken = deviceConfirmationToken
        this.handleTimer()
        this.$snackbar({ message: 'Código de verificação enviado para o e-mail cadastrado.', snackbarColor: 'success' })
      } catch (error) {
        console.log(error)
        if (error.graphQLErrors?.[0]?.message === 'User is admin') {
          this.$snackbar({ message: 'Esse usuário é admin.', snackbarColor: 'error' })
        } else if (error.graphQLErrors?.[0]?.message === 'authentication.error.device.request.verification') {
          this.$snackbar({ message: 'Houve um erro ao tentar enviar o codigo novamente', snackbarColor: 'error' })
        }
      }
      this.loading = false
    },
    clearInterval() {
      clearInterval(this.interval)
      this.interval = null
    },

    handleTimer() {
      this.timer = 60
      this.interval = setInterval(() => {
        this.timer--
        if (this.timer === 0) this.clearInterval()
      }, 1000)
    },

    forgotPassword() {
      this.recoverPassword = true
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.login-title {
  font-weight: 700;
  font-size: 22px;
  color: $cinza-d10;
}
.login-card {
  width: 100%;
  min-width: 400px;
  max-width: 480px;
  border-radius: 6px;
}

.is-mobile {
  background-image: url(https://web.mercafacil.com/img/bg-banner-login.784b55b0.svg) !important;
  background-position: center center;
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: cover !important;
  background: #002040;
  height: 100vh;
  width: 100vw !important;
  max-width: 100vw !important;
  display: flex;
  padding: 32px !important;

  .body-card-mobile {
    padding: 8px !important;
    background-color: #fff;
    align-self: center;
    width: 100%;
    max-width: 480px;
    margin: auto;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: box-shadow;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  }
}

.overflow-hidden {
  overflow: hidden;
}
.no-text-select {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}
</style>
