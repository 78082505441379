<template>
  <div id="buttonDiv" />
</template>

<script>
export default {
  name: 'GoogleLoginButton',
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    window.google.accounts.id.initialize({
      client_id: this.clientId,
      callback: this.callback,
    })
    window.google.accounts.id.renderButton(document.getElementById('buttonDiv'), { theme: 'outline', size: 'large', width: 300 })
  },
  methods: {
    callback(response) {
      if (response.credential) {
        this.$emit('onSuccess', response.credential)
      } else {
        this.$emit('onError')
      }
    },
  },
}
</script>
